import * as React from "react"
import { Link, navigate } from "gatsby"
import { Helmet } from "react-helmet"

const HpSignUpPage = ({ location }) => {
    React.useEffect(() => {
        navigate("/")
    }, [])

    return <>{/* The page will redirect to the homepage immediately */}</>
}

export default HpSignUpPage
